import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Language } from './app/shared/language/language/language.model';
import { ELanguage } from './app/shared/language/language/language.enum';

// localStorage.setItem('LANGUAGE', JSON.stringify(new Language()[ELanguage.he]));

if (environment.production) {

	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
