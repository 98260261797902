import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'deliveryType'
})
export class DeliveryTypePipe implements PipeTransform {

	transform(value: string, ...args: unknown[]): string {
		if (value.toLowerCase() === 'now') {
			return 'מעכשיו לעכשיו';
		} else if (value.toLowerCase() === 'later') {
			return 'עד 24 שעות';
		} else {
			return '';
		}
	}

}
