import { ELanguage } from './language.enum';

export class Language {

	[ELanguage.he] = {
		title: `עברית`,
		dir: `rtl`,
		lang: ELanguage.he
	};

	[ELanguage.en] = {
		title: `english`,
		dir: `ltr`,
		lang: ELanguage.en
	};
}
