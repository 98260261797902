import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';

import { Full_ROUTES } from './shared/routes/full-layout.routes';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';

import { AuthGuard } from './shared/auth/auth-guard.service';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { LandingPageComponent } from './landing-page/landing-page.component';

const appRoutes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: LandingPageComponent
	},
	{path: 'privacy-policy', component: PrivacyPolicyComponent},
	{path: 'terms-of-use', component: TermsOfUseComponent},
	{path: '', component: FullLayoutComponent, data: {title: 'full Views'}, children: Full_ROUTES, canActivate: [AuthGuard]},
	{path: '', component: ContentLayoutComponent, data: {title: 'content Views'}, children: CONTENT_ROUTES},
	{
		path: '**',
		redirectTo: 'pages/error'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes)],
	exports: [RouterModule]
})

export class AppRoutingModule {
}
