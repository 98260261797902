<div class="wrapper">
    <div class="main-panel">
        <!-- BEGIN : Main Content-->
        <div class="main-content">
            <div class="content-overlay"></div>
            <div class="content-wrapper p-0">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
