import { Routes } from '@angular/router';
import { AdminGuard } from '../auth/admin-guard.service';

export const Full_ROUTES: Routes = [
	{
		path: 'dashboard',
		loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
		canActivate: [AdminGuard]
	},
	{
		path: 'clients',
		loadChildren: () => import('../../clients/clients.module').then(m => m.ClientsModule),
	},
	{
		path: 'nutritionists',
		loadChildren: () => import('../../nutritionists/nutritionists.module').then(m => m.NutritionistsModule),
		canActivate: [AdminGuard]
	},
	{
		path: 'ads',
		loadChildren: () => import('../../ads/ads.module').then(m => m.AdsModule),
		canActivate: [AdminGuard]
	},
	{
		path: 'coupons',
		loadChildren: () => import('../../coupons/coupons.module').then(m => m.CouponsModule),
		canActivate: [AdminGuard]
	},
	{
		path: 'products',
		loadChildren: () => import('../../products/products.module').then(m => m.ProductsModule),
		canActivate: [AdminGuard]
	},
	{
		path: 'motivation-quotes',
		loadChildren: () => import('../../motivation-quotes/motivation-quotes.module').then(m => m.MotivationQuotesModule),
		canActivate: [AdminGuard]
	},
	{
		path: 'school',
		loadChildren: () => import('../../school/school.module').then(m => m.SchoolModule),
		canActivate: [AdminGuard]
	}
];
