import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'parkingType'
})
export class ParkingTypePipe implements PipeTransform {

	transform(value: string, ...args: unknown[]): string {
		if (value.toLowerCase() === 'free_parking') {
			return 'חניה חינם';
		} else if (value.toLowerCase() === 'paid_parking') {
			return 'חניה בתשלום';
		} else if (value.toLowerCase() === 'no_parking_available') {
			return 'אין חניה';
		} else if (value.toLowerCase() === 'getting_package_without_parking') {
			return 'מוריד למטה';
		} else {
			return '';
		}
	}

}
