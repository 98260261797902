import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => SearchComponent),
		multi: true
	}]
})
export class SearchComponent implements ControlValueAccessor, OnInit {
	@Input() placeholder: string;
	@Input() items: any[];
	@Input() key: string;

	val = '';
	myControl = new FormControl();
	filteredOptions: Observable<string[]>;

	constructor() {
	}

	ngOnInit(): void {
		this.filteredOptions = this.myControl.valueChanges
			.pipe(
				startWith(''),
				map(value => this._filter(value))
			);
	}

	writeValue(value: any): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
	}

	onTouch(val: any) {
	}

	onChange(val: any) {
	}

	set value(val) {
		if (val !== undefined && this.val !== val) {
			this.val = val;
			this.onChange(val);
			this.onTouch(val);
		}
	}

	private _filter(value: string): any[] {
		const filterValue = value.toLowerCase();

		return this.items.filter(option => (this.key ? option[this.key] : option).toLowerCase().includes(filterValue));
	}

}
