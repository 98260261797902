<h1>תנאי שימוש</h1>
<p>
    ברוך הבא לאפליקציה "התזונה של קים" המספקת לך מידע לניהול התזונה היומיומית שלך ומהווה פלטפורמה לקבלת יעוץ וליווי ע"י
    תזונאיות קליניות.
</p>
<p>האפליקציה מופעלת ע"י Liveat שכתובתה imkimgafson@gmail.com ("המפעיל").
</p>
<p>
    ההרשמה לאפליקציה מוגבלת לגילאי 18 ומעלה [כאשר המשתמש מתחת לגיל 18 יש לקבל אישורם של ההורים / אפוטרופוס]. השימוש
    באפליקציה ובכלל זה בתכנים הכלולים בה ובשירותים השונים הפועלים בה מעיד על הסכמתך לתנאים אלה. בעת הרשמתך לאפליקציה
    ותשלום עבור השירות תוכל ליהנות ממידע, טיפים, הכוונה, תפריטים מומלצים וצ'ט עם תזונאית.
</p>
<p>במידה ואינך מסכים להיות מחויב על ידי התנאים כדלקמן, אנא הימנע מלעשות כל שימוש באפליקציה.
</p>
<p>
    בכניסתך לאפליקציה הנך מאשר באופן מלא את תנאי השימוש כאמור, לרבות מדיניות הפרטיות, ומסכים, ללא כל תנאי ו/או הסתייגות,
    שהתנאים יחייבו אותך לכל דבר ועניין, ומתחייב לפעול על פי החוקים הרלוונטיים ללא הגבלה ו/או סייג.
</p>
<p>
    ידוע לך כי המידע והתכנים באפליקציה זו נועדו לצורך מתן ידע והמלצות, וכי המידע אינו מיועד בשום מקרה לשמש כתחליף לייעוץ
    רפואי ובריאותי מקצועי ואינו מהווה בבחינת עצה רפואית ו/או חוות דעת רפואית וכי בכל מקרה של בעיה רפואית או רגישות
    (למזון או תרופות) יש לפנות מייד לאיש מקצוע מוסמך. בכניסתך לאפליקציה ובהרשמתך אתה מאשר כי ידוע לך שיישום העצות
    והטיפים הינם באחריותך בלבד.
</p>
<p>
    עם העברת התשלום למפעיל ולאחר הזנת שם משתמש וסיסמא תקבל גישה אישית, שלא למטרת העברתה ו/או מיסחורה, המאפשרת לך כניסה
    למידע וטיפים ללא הגבלה למספר הכניסות וכניסה לצ'ט עם תזונאית וקבלת תפריט תזונה אישי בתשלום, והכל על פי מדיניות
    המפעיל.
</p>
<p>
    פרטי הגישה הנם לשימושך האישי בלבד, והינך מתחייב שלא להפיץ ו/או למכור ו/או לסחור בין בתשלום ובין שלא בתשלום בפרטי
    הגישה כאמור. מוסכם ומובהר כי הפרה של מחויבות זו תזכה את המפעיל בסעד על פי תנאי שימוש אלו או הדין.
</p>
<p>תנאי השימוש מנוסחים מטעמי נוחות בלשון זכר אולם הם מתייחסים לנשים וגברים כאחד.
</p>
<p>תנאים לשימוש באפליקציה, style: TextStyle(decoration: TextDecoration.underline)),
</p>
<p>
    1. האפליקציה מציעה פלטפורמה לניהול התזונה היומיומית ("פלטפורמת המידע") וכן אפשרות ליווי ע"י תזונאית ("תוכנית
    הליווי"). ההשתתפות בפלטפורמת המידע ובתוכנית הליווי ("התוכנית") מותנית במילוי פרטיך האישיים ובהסכמתך לתנאי ההצטרפות
    לתוכנית ("תנאי השירות").
</p>
<p>
    2. ההשתתפות התוכנית הליווי בחלק כרוכה בתשלום ובמסירת פרטי כרטיס האשראי שלך לנציג המפעיל. ניתן להפסיק את השתתפותך
    בתוכנית הליווי בכל עת עפ״י מדיניות הביטולים המקובלת של המפעיל.
</p>
<p>
    3. לצורך השתתפות בתוכנית הליווי הינך נדרש למסור פרטים ומידע אישי, לרבות בדיקות דם, עברך הרפואי, נטילת תרופות ומידע
    על אורחות חיים ("מידע אישי"). בכניסתך לאפליקציה, מילוי פרטים ומידע אישי הנך מתחייב כי הפרטים שמסרת הינם פרטיך המלאים
    והנכונים ובאחריותך לעדכן מעת לעת על פי הצורך. פרטים שגויים ו/או חסרים עלולים לפגוע באיכות השירותים הניתנים
    באפליקציה. המפעיל ו/או מי מטעמו אינו אחראי, במישרין ו/או בעקיפין, לשמירת המידע הרפואי ו/או המידע האישי אותו מסרת
    ו/או לשמירתו על פי דין לרבות דיני הגנת הפרטיות ודיני שמירת הסודיות הרפואית.
</p>
<p>
    4. על המשתמש אין חובה חוקית בדין למסור את המידע האישי כאמור, אולם משתמש שאינו מסכים למסירת המידע האישי, יתכן כי לא
    יוכל להשתתף בתוכנית הליווי.
</p>
<p>
    5. בעצם הזנת פרטיך האישיים, הינך נותן הסכמתך שפרטיך אלו יכללו, בכפוף להוראות כל דין, במאגר המידע של המפעיל ולכך
    שהמפעיל יעשה בפרטים שימושים שונים, לרבות שליחת דיוור אלקטרוני ישיר. בכל שלב הינך רשאי לפנות ישירות למפעיל בכתובת
    imkimgafson@gmail.com לשם הסרת פרטיך האישיים ממאגר המידע של המפעיל ו/או מי מטעמו.
</p>
<p>
    6. המפעיל רשאי, על פי שיקול דעתו, לפנות אליך לקבלת משוב ו/או שאלון. במידה ואתה מסכים, על פי שיקול דעתך הבלעדי והמלא,
    לתת משוב או למלא שאלון, הנך מתחייב לשתף פעולה עם המפעיל ו/או מי מטעמו ולדרג את שביעות רצונך מהשימוש באפליקציה בכנות
    ובתום לב על פי פרמטרים שיימסרו לך על ידי המפעיל ו/או מי מטעמו. בהקשר זה, עליך לאשר כי אינך עובד ו/או קשור אל המפעיל
    עליו אתה מעביר דיווח ו/או בתחרות עמו.
</p>
<p>
    7. התכנים באפליקציה הנם יצירות שזכויות היוצרים בהן הנן של המפעיל ו/או מי מטעמו ו/או של צדדים הקשורים אליו ולא ניתן
    להשתמש בהם ללא הרשאה כתובה מהמפעיל, פרט למותר לפי התנאים או למופיע בטקסט באפליקציה. המפעיל אינו ערב ואינו מתחייב
    ששימושך בחומר המוצג באפליקציה לא יפגע בזכויותיו של צד שלישי כלשהו. אין לראות בחומר כלשהו המוצג באפליקציה כדבר המעניק
    לך הרשאה, במישרין ו/או בעקיפין, להשתמש בכל קניין רוחני השייך למפעיל ו/או לצד ג' כלשהו (לרבות וללא הגבלה לפטנט, סימן
    רשום, סימן מסחר, זכויות יוצרים או סוד מסחרי).
</p>
<p>
    8. השימוש באפליקציה הנו על אחריותך הבלעדית. בשום מקרה המפעיל ו/או מי מטעמו לא יהיו אחראיים לכל נזק, מכל סוג שהוא,
    לרבות אך מבלי לגרוע מכלליות האמור לעיל, נזקים ישירים, תוצאתיים, עקיפים או עונשיים, או נזקים הנובעים מאובדן רווחים,
    אובדן נתונים. הגבלת אחריות זו חלה גם על כל נזק או וירוס אשר עלולים לפגוע בציוד המחשבים שלך.
</p>
<p>
    9. מבלי לגרוע מהאמור לעיל, המפעיל ו/או מי מטעמו אינם נושאים בכל אחריות ביחס לתכנים המופיעים באפליקציה, לרבות אך מבלי
    לגרוע מכלליות האמור, כתבות, תמונות, מאמרים, סקירות, נתונים, ידיעות, עצות, הערכות, הנחיות ו/או כל מידע אחר בכל פורמט
    שהוא אשר יועמד לרשותך בכל אמצעי שהוא, הקיים כיום או יהיה קיים בעתיד (להלן: "תכני האפליקציה"), בין אם תכנים אלה
    בבעלות המפעיל ובין אם הם בבעלות צד שלישי כלשהו, מהימנותם, תוכנם, דיוקם, אמינותם, זמינותם והשפעתם על המשתמשים
    באפליקציה ו/או כל צד שלישי הנחשף לתכני האפליקציה בכל דרך אחרת. מבלי להגביל את האמור לעיל, כל דבר באפליקציה מסופק לך
    כמות שהוא ("AS IS") ללא אחריות מסוג כלשהו, למפעיל ו/או למי מטעמו, בין במישרין ובין בעקיפין.
</p>
<p>
    10. זכויות היוצרים של אנשים או מקומות באפליקציה הנם בבעלותם של המפעיל ו/או צד שלישי כלשהו. שימוש בתמונות אלו על ידי
    המשתמש ו/או מי מטעמו ובהרשאתו, אסור אלא אם ניתנה הרשאה מפורשת בתנאים אלו במייל, או במקום אחר באפליקציה. כל שימוש
    בלתי מורשה בתמונות ו/או במלל עלול להוות הפרת חוקים בגין זכויות יוצרים, סימני מסחר, חוק הגנת הפרטיות וכן חוקים ותקנות
    רלוונטיים. המפעיל מפעיל את כל האמצעים העומדים לרשותו במסגרת החוק לשם שמירה קפדנית על זכויותיו הקנייניות, לרבות פניה
    לערכאות משפטיות ככל שיידרש.
</p>
<p>
    11. סימני המסחר, הלוגו וכן כל סימן אחר המוצג באפליקציה בין אם הם רשומים ובן אם לאו (להלן: "סימני המסחר") הנם סימני
    מסחר בבעלות מלאה של המפעיל ו/או בעל זכות אחר. אין לפרש שום דבר מהאמור באפליקציה זה כאילו הוא מהווה מתן רישיון או
    זכות, מכללא, או מכח השתק, או בדרך אחרת, בסימני המסחר או בקשר אליהם, ללא רשות מפורשת בכתב מאת המפעיל ו/או מי מטעמו.
    חל איסור מוחלט על שימוש של המשתמש בסימני המסחר שבאפליקציה או בתכנים המוצגים בו, מלבד כפי שנקבע בתנאים והוראות אלה.
    המפעיל מפעיל את כל האמצעים העומדים לרשותו במסגרת החוק לשם שמירה קפדנית על זכויותיו הקנייניות, לרבות פניה לערכאות
    משפטיות ככל שיידרש.
</p>
<p>
    12. מוסכם ומובהר על ידך כי המפעיל לא בדק את כל הדפים המקושרים לאפליקציה, ואינו אחראי למובא בדפים שנוספו לאפליקציה או
    לאתרי אינטרנט שקושרו לאפליקציה. התחברות לאתרים שנוספו לאפליקציה או לכל אתרים מקושרים אחרים הנה על אחריותך הבלעדית
    ואתה משחרר את המפעיל ו/או מי מטעמו באופן בלתי חוזר מכל אחריות בקשר לאותם אתרים.
</p>
<p>
    13. תנאי השימוש כאמור ביחד עם המדיניות בנושא פרטיות וכל הסכם אחר שנכרת ביני לבין המפעיל ו/או מי מטעמו בקשר למוצר או
    לשירות מסוים של המפעיל ו/או מי מטעמו, ממצים את מלוא ההסכמה בינך לבין המפעיל ו/או מי מטעמו וגוברים על כל ההסכמים,
    המצגים וההבנות האחרים, בכתב או בעל פה.
</p>
<p>
    14. ידוע לך כי המפעיל רשאי לשנות ו/או לתקן תנאים והוראות אלה, המחירים הרשומים באפליקציה ואת מדיניות הפרטיות בכל זמן,
    על פי שיקול דעתו הבלעדי והמלא, ומבלי לתת לך הודעה על כך. ולפיכך עליך לבדוק תנאים והוראות אלה באופן סדיר על מנת לבדוק
    האם נעשו בהם שינויים או תיקונים, שכן המשך השימוש שלך באתר לאחר שינוי או תיקון מהווה אות להסכמתך ולנכונותך לקבל תנאים
    והוראות אלה כפי ששונו.
</p>
<p>
    15. אם ייקבע כי הוראה כלשהי אשר נכללת בתנאים והוראות אלה אינה חוקית, אינה תקפה או אינה ניתנת לאכיפה על פי הדין הקיים
    או על פי כל דין עתידי הסעיף או ההוראה שיקבע כי אינם חוקיים, אינם תקפים או אינם ניתנים לאכיפה יופרדו משאר התנאים
    וההוראות מבלי להשפיע על האפשרות לאכוף את כל ההוראות והסעיפים הנותרים.
</p>
<p>
    16. הנך מתחייב להגן על המפעיל ולשפות אותו ולמנוע פגיעה בו ו/או במי מטעמו בגין כל הפסד, פיצויים או עלויות, ולרבות
    שכ"ט סביר של עו"ד, הנובעים מתביעה, תובענה או דרישה המופנית מצד ג' והנובעת משימושי באתר ו/או מהפרת התנאים (כהגדרתם
    לעיל). אין בשיפוי כאמור לעיל כדי לגרוע מהסעדים העומדים לרשות המפעיל ו/או מי מטעמו על פי דין.
</p>
<p>
    17. על האפליקציה יחולו דיני מדינת ישראל בלבד. כל סכסוך ו/או שאלה משפטית הקשורה לאתר, בשימוש בו , בתנאי השימוש ו/או
    במדיניות הפרטיות יהיו תחת השיפוט הבלעדי של בתי המשפט המוסמכים בתל אביב-יפו.
</p>
<p>כל הזכויות שמורות לקים גפסון 2021 ©</p>
