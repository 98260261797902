import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { EUserType } from '../models/enums';

@Injectable()
export class AdminGuard implements CanActivate {

	constructor(private authService: AuthService, private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const isAuth = this.authService.isAuthenticated();
		if (isAuth) {
			if (this.authService.userDetails?.value?.type === EUserType.Admin) {
				return true;
			} else {
				this.router.navigate(['/clients']);
			}
		} else {
			this.router.navigate(['/pages', 'login']);
		}
	}
}
