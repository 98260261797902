import { Pipe, PipeTransform } from '@angular/core';
import { get as _get } from 'lodash';

export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
	return typeof obj === 'undefined' || obj === null || obj as any === 'undefined' || obj as any === 'null';
}

@Pipe({
	name: 'search'
})
export class SearchPipe implements PipeTransform {
	public transform(value, keys: string, term: string | any[], sensitive = false, innerKey: string) {
		if (isNullOrUndefined(term)) {
			return value;
		}

		if (Array.isArray(term) && term.length > 0) {
			return (value || []).filter((item) => keys.split(',').some(key => {
				if (!isNullOrUndefined(_get(item, key))) {
					if (Array.isArray(_get(item, key))) {
						return _get(item, key).some(innerValue => term.some(t => t === (innerKey ? _get(innerValue, innerKey) : innerValue)));
					} else {
						return !isNullOrUndefined(_get(item, key)) && term.indexOf(_get(item, key)) > -1;
					}
				}
			}));
		}

		return (value || []).filter((item) =>
			keys.split(',').some(key =>
				!isNullOrUndefined(_get(item, key)) && sensitive ?
					new RegExp(term as string, 'g').test(_get(item, key)) :
					new RegExp(term as string, 'gi').test(_get(item, key))));
	}
}
