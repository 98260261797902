export enum EUserType {
	Admin = 'Admin',
	Regular = 'Regular',
	Nutritionist = 'Nutritionist'
}

export enum EUserStatus {
	Pending = 'Pending',
	Approved = 'Approved'
}

export enum NotificationType {
	SMS = 'SMS',
	Email = 'Email',
	All = 'All'
}

export enum EGender {
	Male = 'Male',
	Female = 'Female'
}

export enum EActivityLevel {
	Inactive = 'Inactive',
	Low = 'Low',
	Medium = 'Medium',
	Active = 'Active',
}

export enum ELiquidType {
	Cup = 'Cup',
	Bottle = 'Bottle'
}

export enum EWeightingPeriod {
	Weekly = 'Weekly',
	Monthly = 'Monthly'
}

export enum EWeek {
	Sunday = 'Sunday',
	Monday = 'Monday',
	Tuesday = 'Tuesday',
	Wednesday = 'Wednesday',
	Thursday = 'Thursday',
	Friday = 'Friday',
	Saturday = 'Saturday',
}

export const weeks = [EWeek.Sunday, EWeek.Monday, EWeek.Tuesday, EWeek.Wednesday, EWeek.Thursday, EWeek.Friday, EWeek.Saturday];

export enum EBodyType {
	Apple = 'Apple',
	Pear = 'Pear'
}

export enum EClientType {
	Free = 'Free',
	Medium = 'Medium',
	Premium = 'Premium'
}
