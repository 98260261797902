import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';

export const HROUTES: RouteInfo[] = [
	// {
	// 	path: '/dashboard', title: 'Dashboard', icon: 'ft-dashboard', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	// },
	{
		path: '/clients', title: 'Clients', icon: 'ft-delivery', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	},
	{
		path: '/clients/paying', title: 'Paying Clients', icon: 'ft-credit-card', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	},
	{
		path: '/clients/inactive', title: 'Inactive Clients', icon: 'ft-delivery', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	},
	{
		path: '/products', title: 'Products', icon: 'ft-home', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	},
	{
		path: '/motivation-quotes', title: 'Motivation Quotes', icon: 'ft-dollar', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	},
	{
		path: '/ads', title: 'Ads', icon: 'ft-thumbs-up', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	},
];
