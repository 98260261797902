import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { values } from 'lodash';
import { first } from 'rxjs/operators';
import { ELanguage } from './language.enum';
import { Language } from './language.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LanguageService {
	directionSubject = new BehaviorSubject<string>('ltr');
	languageSubject = new BehaviorSubject<ELanguage | string>(ELanguage.en);

	constructor(private readonly translateService: TranslateService) {
		translateService.addLangs(values(ELanguage));

		const browserLang: string = this.translateService.getBrowserLang();

		const language = browserLang.match(/en|he/) ? browserLang : 'he';

		this.languageSubject.next(language);

		if (language === 'he') {
			this.directionSubject.next('rtl');
		}

		this.init();
	}

	private readonly storageKey = `LANGUAGE`;
	private readonly defaultLanguage: ELanguage = ELanguage.he;

	private async init() {
		const language = await this.getLanguage();

		this.changeLanguageTasks(language.lang, language.dir);
	}

	async getLanguage(): Promise<{ title, lang, dir }> {
		const fromStorage = localStorage[this.storageKey];

		if (fromStorage) {
			return JSON.parse(fromStorage);
		} else {
			const result = new Language()[this.defaultLanguage];
			localStorage[this.storageKey] = JSON.stringify(result);

			return result;
		}
	}

	async setLanguage(value: ELanguage | string) {
		const newLanguage = new Language()[value];
		localStorage[this.storageKey] = JSON.stringify(newLanguage);

		this.changeLanguageTasks(value, newLanguage.dir);
	}

	private changeLanguageTasks(value: ELanguage | string, direction: string) {
		this.translateService.setDefaultLang(value);
		this.translateService.use(value);
		// this.changeMomentLocale(value);
		this.setDirection(direction);
		this.languageSubject.next(value);
	}

	setDirection(direction: string) {
		if (direction === 'ltr') {
			document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
			document.getElementsByTagName('body')[0].setAttribute('dir', 'ltr');
		} else if (direction === 'rtl') {
			document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
			document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl');
		}

		this.directionSubject.next(direction);
	}

	valueOf(key: string): Promise<string> {
		return this.translateService.get(key).pipe(first()).toPromise<string>();
	}

	// changeMomentLocale(lang) {
	//   if (lang === 'he') {
	//     moment.updateLocale(lang, {
	//       weekdays: [
	//         'א\'', 'ב\'', 'ג\'', 'ד\'', 'ה\'', 'ו\'', 'ש\''
	//       ]
	//     });
	//   } else if (lang === 'en') {
	//     moment.updateLocale(lang, {
	//       weekdays: [
	//         'S', 'M', 'T', 'W', 'T', 'F', 'S'
	//       ]
	//     });
	//   }
	// }
}
