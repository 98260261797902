import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { filter, map } from 'rxjs/operators';
import { IResponse } from '../models/response.interface';
import { Observable } from 'rxjs';

const SERVER_URL = environment.serverUrl;
const endpoint = SERVER_URL + '/files';

@Injectable({
	providedIn: 'root'
})
export class FilesService {

	constructor(private http: HttpClient) {
	}

	uploadFile(fileToUpload: File): Observable<string> {
		const formData = new FormData();
		formData.append('fileToUpload', fileToUpload);
		return this.http.post<IResponse<string>>(`${endpoint}/upload`, formData).pipe(
			filter(response => response.success),
			map(response => response.data)
		);
	}
}
