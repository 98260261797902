import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'packageSize'
})
export class PackageSizePipe implements PipeTransform {

	transform(value: string, ...args: unknown[]): string {
		if (value.toLowerCase() === 'small') {
			return 'קטן';
		} else if (value.toLowerCase() === 'medium') {
			return 'בינוני';
		} else if (value.toLowerCase() === 'large') {
			return 'גדול';
		} else {
			return '';
		}
	}

}
