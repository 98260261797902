import { RouteInfo } from './vertical-menu.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
	// {
	// 	path: '/dashboard', title: 'Dashboard', icon: 'ft-monitor', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	// },
	{
		path: '/clients', title: 'Clients', icon: 'ft-users', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	},
	{
		path: '/clients/paying', title: 'Paying Clients', icon: 'ft-credit-card', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	},
	{
		path: '/clients/inactive', title: 'Inactive Clients', icon: 'ft-delivery', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	},
	{
		path: '/products', title: 'Products', icon: 'ft-home', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	},
	{
		path: '/nutritionists', title: 'Nutritionists', icon: 'ft-briefcase', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	},
	{
		path: '/motivation-quotes', title: 'Motivation Quotes', icon: 'ft-music', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	},
	{
		path: '/school', title: 'School', icon: 'ft-home', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	},
	{
		path: '/ads', title: 'Ads', icon: 'ft-thumbs-up', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	},
	{
		path: '/coupons', title: 'Coupons', icon: 'ft-thumbs-up', class: 'dropdown nav-item', isExternalLink: false, submenu: []
	},
];
