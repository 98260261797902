import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from 'ngx-spinner';

import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { AdminGuard } from './shared/auth/admin-guard.service';
import { WINDOW_PROVIDERS } from './shared/services/window.service';
import { AuthInterceptorService } from './shared/auth/auth-interceptor.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { PipeModule } from './shared/pipes/pipe.module';
import { MatDialogModule } from '@angular/material/dialog';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { LandingPageComponent } from './landing-page/landing-page.component';

const firebaseConfig = {
	apiKey: 'YOUR_API_KEY',
	authDomain: 'YOUR_AUTH_DOMAIN',
	databaseURL: 'YOUR_DATABASE_URL',
	projectId: 'YOUR_PROJECT_ID',
	storageBucket: 'YOUR_STORAGE_BUCKET',
	messagingSenderId: 'YOUR_MESSAGING_SENDER_ID',
	appId: 'YOUR_APP_ID',
	measurementId: 'YOUR_MEASUREMENT_ID'
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
	wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent, PrivacyPolicyComponent, TermsOfUseComponent, LandingPageComponent],
	imports: [
		ToastrModule.forRoot({positionClass: 'toast-top-left'}),
		BrowserAnimationsModule,
		AppRoutingModule,
		SharedModule,
		HttpClientModule,
		AngularFireModule.initializeApp(firebaseConfig),
		AngularFireAuthModule,
		NgbModule,
		MatDialogModule,
		NgxSpinnerModule,
		DeviceDetectorModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		PerfectScrollbarModule,
		FormsModule,
		MatSortModule,
		PipeModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true,
		},
		{provide: MAT_DATE_LOCALE, useValue: 'he-IL'},

		AuthService,
		AuthGuard,
		AdminGuard,
		{provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
		WINDOW_PROVIDERS
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
