import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'vehicleType'
})
export class VehicleTypePipe implements PipeTransform {

	transform(value: string, ...args: unknown[]): string {
		if (value.toLowerCase() === 'car') {
			return 'רכב';
		} else if (value.toLowerCase() === 'bike') {
			return 'אופניים';
		} else if (value.toLowerCase() === 'motorcycle') {
			return 'אופנוע';
		} else {
			return '';
		}
	}

}
