<!doctype html>
<html lang="en" dir="ltr">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1"/>
    <title>Runner onepage - html</title>
    <meta name="keywords" content="Liveat - Health and Fitness App">
    <meta name="description" content="Liveat - Health and Fitness App">
</head>
<body>
<div id="content-wrapper">
    <header class="header header--bg">
        <div class="container">
            <nav class="navbar">
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <a class="navbar-brand"></a>
                </div>
                <div class="collapse navbar-collapse" id="myNavbar">
                    <ul class="nav navbar-nav pull-right">
                        <li><a [routerLink]="'/privacy-policy'">PRIVACY POLICY</a></li>
                        <li><a [routerLink]="'/terms-of-use'">TERMS OF USE</a></li>
                    </ul>
                </div>
            </nav>
            <div class="row">
                <div class="col-lg-6">
                    <img class="img-responsive" src="assets/landing-page/images/running-man.png" alt="">
                </div>
                <div class="col-lg-6 header__content">
                    <h1 class="title">Liveat - Health and Fitness
                        <div class="page-section__title-style">
                            <span class="first-line"></span>
                            <span class="second-line"></span>
                        </div>
                    </h1>
                    <p>
                        Liveat app helps you get healthier.
                        We take you as our client to a different level, letting you track your meals,
                        track your daily steps, weight and progress!
                    </p>
                    <a class="header__button" href="https://onelink.to/zter3n">DOWNLOAD OUR APP</a>
                    <!--                    <a class="header__button" href="#">DISCOVER</a>-->
                </div>
            </div>
        </div>
    </header>

    <section class="steps">
        <div class="container">
            <div class="page-section text-center">
                <h2 class="page-section__title">3 simple healthy steps</h2>
                <div class="page-section__title-style">
                    <span class="first-line"></span>
                    <span class="second-line"></span>
                </div>
                <p class="page-section__subtitle">Using our 3 methods, you'll find yourself improving every zone of
                    healthiness in life.</p>
                <div class="row">
                    <div class="col-md-4">
                        <div class="text-center steps__single steps__single-first">
                            <img src="assets/landing-page/images/activity_bottom_image.svg" alt="">
                            <p style="text-align: center; font-size: 2rem; font-weight: bold">STEPS & ACTIVITY</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="text-center steps__single">
                            <img src="assets/landing-page/images/weight_bottom_image.svg" alt="">
                            <p style="text-align: center; font-size: 2rem; font-weight: bold">WEIGHT & DIET</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="text-center steps__single steps__single-last">
                            <img src="assets/landing-page/images/bmi_bottom_image.svg" alt="">
                            <p style="text-align: center; font-size: 2rem; font-weight: bold">STATS & PROGRESS</p>
                        </div>
                    </div>
                </div>
                <a class="button" href="https://onelink.to/zter3n">DOWNLOAD NOW</a>
            </div>
        </div>
    </section>

    <!--    <section class="video video&#45;&#45;bg">-->
    <!--        <div class="container">-->
    <!--            <div class="page-section">-->
    <!--                <div class="row">-->
    <!--                    <div class="col-md-7 video__left">-->
    <!--                        <a data-toggle="modal" data-target="#myModal" href="#"><i class="material-icons">play_circle_filled</i></a>-->
    <!--                    </div>-->
    <!--                    <div class="col-md-5">-->
    <!--                        <div class="video__right text-center">-->
    <!--                            <h2 class="video__title">Watch the video</h2>-->
    <!--                            <div class="video__title-style">-->
    <!--                                <span class="first-line"></span>-->
    <!--                                <span class="second-line"></span>-->
    <!--                            </div>-->
    <!--                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et</p>-->
    <!--                            <a class="button button&#45;&#45;white" href="#">TRY NOW</a>-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </section>-->

    <!-- video-popup -->
    <!--    <div class="container">-->
    <!--        <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">-->
    <!--            <div class="modal-dialog" role="document">-->
    <!--                <div class="modal-content">-->
    <!--                    <div class="modal-body">-->
    <!--                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
    <!--                            <span aria-hidden="true">&times;</span>-->
    <!--                        </button>-->

    <!--                        <div class="embed-responsive embed-responsive-16by9">-->
    <!--                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/wKtPBOb80II?autoplay=0" frameborder="0" allowfullscreen="" id="video"  allowscriptaccess="always"></iframe>-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->
    <!-- video-popup-end -->

    <!--    <section class="sponsors sponsors&#45;&#45;bg">-->
    <!--        <div class="container">-->
    <!--            <div class="row">-->
    <!--                <div class="col-lg-2 sponsors__single">-->
    <!--                    <img src="assets/landing-page/images/sponsors1.png" alt="">-->
    <!--                </div>-->
    <!--                <div class="col-lg-2 sponsors__single">-->
    <!--                    <img src="assets/landing-page/images/sponsors2.png" alt="">-->
    <!--                </div>-->
    <!--                <div class="col-lg-2 sponsors__single">-->
    <!--                    <img src="assets/landing-page/images/sponsors3.png" alt="">-->
    <!--                </div>-->
    <!--                <div class="col-lg-2 sponsors__single">-->
    <!--                    <img src="assets/landing-page/images/sponsors4.png" alt="">-->
    <!--                </div>-->
    <!--                <div class="col-lg-2 sponsors__single">-->
    <!--                    <img src="assets/landing-page/images/sponsors5.png" alt="">-->
    <!--                </div>-->
    <!--                <div class="col-lg-2 sponsors__single">-->
    <!--                    <img src="assets/landing-page/images/sponsors6.png" alt="">-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </section>-->

    <!--    <section class="testimonial">-->
    <!--        <div class="container">-->
    <!--            <div class="page-section">-->
    <!--                <div class="text-center">-->
    <!--                    <h2 class="page-section__title">Testimonials</h2>-->
    <!--                    <div class="testimonial__title-style">-->
    <!--                        <span class="first-line"></span>-->
    <!--                        <span class="second-line"></span>-->
    <!--                    </div>-->
    <!--                    <p class="page-section__subtitle">Lorem ipsum dolor sit amet, consectetur <a href="#">450</a> adipisicing elit, sed do eiusmod tempor incididunt ut labore et</p>-->
    <!--                </div>-->
    <!--                <div class="row">-->
    <!--                    <div class="col-md-8">-->
    <!--                        <div class="accordion-group">-->
    <!--                            <div class="collapse in indent" id="one">-->
    <!--                                <div class="collapse__wrapper">-->
    <!--                                    <div class="bio bio-1">-->
    <!--                                        <h2>Tyrion Lannister</h2>-->
    <!--                                        <p>Company Name</p>-->
    <!--                                    </div>-->
    <!--                                    <div class="star">-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                    </div>-->
    <!--                                    <p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, </p>-->
    <!--                                </div>-->
    <!--                            </div>-->
    <!--                            <div class="collapse" id="two">-->
    <!--                                <div class="collapse__wrapper">-->
    <!--                                    <div class="bio bio-2">-->
    <!--                                        <h2>Sansa Stark</h2>-->
    <!--                                        <p>Company Name</p>-->
    <!--                                    </div>-->
    <!--                                    <div class="star">-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                    </div>-->
    <!--                                    <p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, </p>-->
    <!--                                </div>-->
    <!--                            </div>-->
    <!--                            <div class="collapse" id="three">-->
    <!--                                <div class="collapse__wrapper">-->
    <!--                                    <div class="bio bio-3">-->
    <!--                                        <h2>Khal Drogo</h2>-->
    <!--                                        <p>Company Name</p>-->
    <!--                                    </div>-->
    <!--                                    <div class="star">-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                    </div>-->
    <!--                                    <p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, </p>-->
    <!--                                </div>-->
    <!--                            </div>-->
    <!--                            <div class="collapse" id="four">-->
    <!--                                <div class="collapse__wrapper">-->
    <!--                                    <div class="bio bio-4">-->
    <!--                                        <h2>Jon Snow</h2>-->
    <!--                                        <p>Company Name</p>-->
    <!--                                    </div>-->
    <!--                                    <div class="star">-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                        <li><i class="material-icons">star</i></li>-->
    <!--                                    </div>-->
    <!--                                    <p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, </p>-->
    <!--                                </div>-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                    <div class="col-md-4 testimonial-right open-default">-->
    <!--                        <button class="button-testimonial button-testimonial-1" data-toggle="collapse" data-target="#one">-->
    <!--                            <div class="bio">-->
    <!--                                <h2>Tyrion Lannister</h2>-->
    <!--                                <p>Company Name</p>-->
    <!--                            </div>-->
    <!--                        </button>-->
    <!--                        <button class="button-testimonial button-testimonial-2" data-toggle="collapse" data-target="#two">-->
    <!--                            <div class="bio">-->
    <!--                                <h2>Sansa Stark</h2>-->
    <!--                                <p>Company Name</p>-->
    <!--                            </div>-->
    <!--                        </button>-->
    <!--                        <button class="button-testimonial button-testimonial-3" data-toggle="collapse" data-target="#three">-->
    <!--                            <div class="bio">-->
    <!--                                <h2>Khal Drogo</h2>-->
    <!--                                <p>Company Name</p>-->
    <!--                            </div>-->
    <!--                        </button>-->
    <!--                        <button class="button-testimonial button-testimonial-4" data-toggle="collapse" data-target="#four">-->
    <!--                            <div class="bio">-->
    <!--                                <h2>Jon Snow</h2>-->
    <!--                                <p>Company Name</p>-->
    <!--                            </div>-->
    <!--                        </button>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </section>-->

    <section class="team team--bg">
        <div class="container">
            <div class="page-section">
                <div class="text-center">
                    <h2 class="page-section__title">Going a little deeper</h2>
                    <div class="team__title-style">
                        <span class="first-line"></span>
                        <span class="second-line"></span>
                    </div>
                    <p class="page-section__subtitle">We'd like to explain our exact impact on your health life</p>
                </div>
                <div class="row gutters-80">
                    <div class="col-md-4">
                        <div class="thumbnail team__single">
                            <div class="bio text-center">
                                <i style="text-align: center; font-size: 1.5rem;">1</i>
                                <h2>Steps</h2>
                                <p>We let you use your default apps to track your steps</p>
                            </div>
                            <div class="caption">
                                <p>Using Google Fit on Android and Apple Health app on iOS, we let you visualize your
                                    results daily and weekly.
                                    More over, if you have a good progress and able to stand in our "10K steps
                                    challenge", we let you have discounts on our diet plans.
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="thumbnail team__single">
                            <div class="bio text-center">
                                <i style="text-align: center; font-size: 1.5rem;">2</i>
                                <h2>Weight</h2>
                                <p>Provide us with your weight</p>
                            </div>
                            <div class="caption">
                                <p>You can rest assure we won't let you miss your weighting days. With notifications
                                    sent according to your setup, we let you insert your weight.
                                    <br>
                                    And obviously, visualize your progress and have some metrics regarding your BMI.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="thumbnail team__single">
                            <div class="bio text-center">
                                <i style="text-align: center; font-size: 1.5rem;">3</i>
                                <h2>Diets</h2>
                                <p>Nutritionists, Tips and Videos</p>
                            </div>
                            <div class="caption">
                                <p>Our clients get access to our resources - tips and videos.
                                    <br>
                                    After contacting us, we also match you with a nutritionist with whom you can chat 24/7 via Whatsapp.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="form form--bg">
        <div class="container">
            <!--            <div class="page-section">-->
            <!--                <div class="text-center">-->
            <!--                    <h2 class="page-section__title page-section__title&#45;&#45;white">Contact us</h2>-->
            <!--                    <div class="form__title-style">-->
            <!--                        <span class="first-line"></span>-->
            <!--                        <span class="second-line"></span>-->
            <!--                    </div>-->
            <!--                    <p class="page-section__subtitle page-section__subtitle&#45;&#45;white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et</p>-->
            <!--                </div>-->
            <!--                <form action="#">-->
            <!--                    <div class="form-group">-->
            <!--                        <input class="form-control" type="text" placeholder="Name">-->
            <!--                    </div>-->
            <!--                    <div class="form-group">-->
            <!--                        <input class="form-control" type="email" placeholder="Email">-->
            <!--                    </div>-->
            <!--                    <div class="form-group">-->
            <!--                        <textarea class="form-control" rows="5" placeholder="Message"></textarea>-->
            <!--                    </div>-->
            <!--                    <div class="row">-->
            <!--                        <div class="col-md-6 checkbox">-->
            <!--                            <input type="checkbox" id="checkbox-1">-->
            <!--                            <label for="checkbox-1"> Subscribe to newslatter</label>-->
            <!--                        </div>-->
            <!--                        <div class="col-md-6 form-button">-->
            <!--                            <input class="button&#45;&#45;form" type="submit" value="SEND">-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </form>-->
            <!--            </div>-->
        </div>
    </section>

    <footer class="footer footer--bg" style="padding: 0">
        <div class="container">
            <div class="row">
                <div class="col-md-4 footer__left" style="display: flex; align-items: center; margin: auto;">
                    <a class="navbar-brand orange" style="margin: 0;"></a>
                    <p>Copyright <span>&copy;</span> Kim Gafson 2021</p>
                </div>
                <!--                <div class="col-md-2 footer__link">-->
                <!--                    <li><a href="#">Link goes here</a></li>-->
                <!--                    <li><a href="#">Another Link Here</a></li>-->
                <!--                    <li><a href="#">Your Text Here</a></li>-->
                <!--                    <li><a href="#">lorem ipsum dolor amet</a></li>-->
                <!--                </div>-->
                <!--                <div class="col-md-2 footer__link">-->
                <!--                    <li><a href="#">Link goes here</a></li>-->
                <!--                    <li><a href="#">Another Link Here</a></li>-->
                <!--                    <li><a href="#">Your Text Here</a></li>-->
                <!--                    <li><a href="#">lorem ipsum dolor amet</a></li>-->
                <!--                </div>-->
                <!--                <div class="col-md-4 footer__social-icons">-->
                <!--                    <li><a href="#"><i class="flaticon-facebook-letter-logo"></i></a></li>-->
                <!--                    <li><a href="#"><i class="flaticon-twitter-logo-silhouette"></i></a></li>-->
                <!--                    <li><a href="#"><i class="flaticon-google-plus"></i></a></li>-->
                <!--                    <li><a href="#"><i class="flaticon-pinterest-logo"></i></a></li>-->
                <!--                </div>-->
            </div>
        </div>
    </footer>

</div>


<script src="assets/landing-page/jquery/jquery-3.2.1.min.js"></script>
<script src="assets/landing-page/bootstrap/js/bootstrap.min.js"></script>
<!-- <script src="assets/landing-page/owl-slider/owl.carousel.min.js"></script> -->

<script>
    $(document).ready(function () {

        $('button').click(function (e) {
            $('.collapse').collapse('hide');
        });


    });
</script>
</body>
</html>

