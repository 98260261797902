<form class="search-form" role="search" *ngIf="!items || !items.length; else autocomplete">
    <div class="position-relative has-icon-right">
        <input type="text" class="form-control round" [(ngModel)]="value" [placeholder]="placeholder"
               [ngModelOptions]="{standalone: true}">
        <div class="form-control-position">
            <i class="ft-search"></i>
        </div>
    </div>
</form>

<ng-template #autocomplete>
    <form class="autocomplete-form position-relative has-icon-right" role="search">
        <input type="text" [placeholder]="placeholder" [formControl]="myControl" [matAutocomplete]="auto"
               class="form-control round" [(ngModel)]="value" [ngModelOptions]="{standalone: true}">
        <div class="form-control-position">
            <i class="icon-search"></i>
        </div>
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="key ? option[key] : option">
                {{key ? option[key] : option}}
            </mat-option>
        </mat-autocomplete>
    </form>
</ng-template>
