<div class="title-container">
    <h1>Privacy Policy</h1>
</div>
<h2>Applicability</h2>
<p>
    This privacy policy applies to the mobile application "Liveat", developed and managed by "Kim Gafson".
    It describes how we handle user data and complies with the policies required by Google Play.
</p>


<h2>Contact Details</h2>
<p>
    Entity Name: Liveat <br>
    Registration Number: 305745457<br>
    Contact Phone: 054-674-8557<br>
    Email: liveatapp@gmail.com
</p>

<h2>General</h2>

<p> The purpose of this policy is to explain the operator's practices regarding user privacy in
    the app and how the operator uses the information provided by users on the website or collected during their use of
    the app.
</p>
<p> Unless explicitly stated otherwise, defined terms will have the meaning given to them in the Terms
    of Use agreement mentioned above.
</p>
<p> 1. The personal information the operator receives from you upon your
    access to the app is solely for registration purposes, for maintaining contact as part of the interaction with you,
    and for implementing it.
</p>
<p> 2. Some of the app's features and/or services require registration, during which
    you will need to provide the aforementioned information. Without limiting the generality of the above, the
    information provided to the operator during registration is given willingly and with your consent. The information
    will be stored in the app's database in accordance with the provisions of the Privacy Protection Law, 1981, and will
    be used as per this section and/or as required by law.
</p>
<p> 3. The operator makes significant commercial efforts
    to protect the integrity and security of the information against unauthorized access by third parties. Information
    collection and confidentiality are conducted in a secure environment, incorporating security technologies, including
    app protection.
</p>
<p> 4. The operator may occasionally send you advertisements via email in accordance with the
    Communications Law (Telecommunications and Broadcasts) (Amendment No. 40), 2008 ("Spam Law"), including new programs
    and offers as they become available. The operator may use the information you provide in the app, as well as
    information collected regarding your usage patterns of the app, to improve app services and/or other offerings, to
    contact you if necessary per the Spam Law, or to analyze and share statistical data with third parties. Such third
    parties and/or their employees and/or representatives are prohibited from using this personal information for any
    purpose other than those outlined in this section.
</p>
<p> 5. The information you provide is subject to the
    provisions of the Privacy Protection Policy in the agreement and/or the law. The operator will, to the best of its
    ability, avoid knowingly disclosing information to third parties, except when required by a court order and/or if
    facing a threat of legal action (criminal or civil) due to actions you have taken on the app. In such cases, the
    operator may share your details with the claimant and/or as per the court order without prior notice.
</p>
<p> 6.
    The app contains links to various websites. The operator strives to link to websites that, to the best of its
    knowledge, do not infringe on the privacy of users of other websites. However, the operator is not responsible for
    the terms of use and/or privacy policies of other websites, their content, or anything related to or arising from
    their use. Your use of links to other websites and/or the use of other websites is at your sole responsibility.
</p>
<p> 7. You are entitled to review the information collected and held by the operator in accordance with these Terms of
    Use and the Privacy Protection Law, 1981. Additionally, if the information in the operator's database is used for
    personalized contact with you, based on your belonging to a population group determined by one or more human
    characteristics included in the database ("Commercial Offer"), then under the Privacy Protection Law, 1981, you have
    the right to request in writing that the information concerning you be deleted from the database. In such cases, the
    operator will delete the information necessary solely for contacting you with commercial offers. Information
    required for the operator's business management - including records of commercial and other transactions you have
    performed in the app - will continue to be stored as per the law but will no longer be used for contacting you.
</p>
<p> 8. The operator reserves the right to amend the Privacy Policy from time to time. If substantial changes are made to
    this policy regarding the use of the information you have provided, a notice will be published in the app or sent
    directly to you at the operator's discretion.
</p>
<p>All rights reserved to Kim Gafson 2021 ©</p>
