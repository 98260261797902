import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IResponse } from 'app/shared/models/response.interface';
import { User } from 'app/shared/models/user.interface';
import { LoginDTOSend } from 'app/shared/models/dto/auth.dto';
import { NutritionistsService } from '../services/nutritionists.service';
import { EUserType } from '../models/enums';

const SERVER_URL = environment.adminEndpoint;
const endpoint = SERVER_URL + '/auth';
export const LOCAL_STORAGE_TOKEN = 'livi-token';
export const LOCAL_STORAGE_PHONE = 'livi-phone';

@Injectable()
export class AuthService {
	tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	userDetails = new BehaviorSubject<User>(null);

	constructor(private http: HttpClient, private nutritionistsService: NutritionistsService) {
		setTimeout(() => {
			if (this.isAuthenticated()) {
				this.fetchUserDetails().subscribe(response => {
					this.userDetails.next(response);

					if (response.type === EUserType.Admin) {
						this.nutritionistsService.getAllNutritionists().subscribe();
					}
				});
			}
		}, 100);
	}

	recoverPassword(email: string) {
		return this.http.post<IResponse<boolean>>(`${endpoint}/forgot`, {email}).pipe(map((res) => res.data));
	}

	registerUser(email: string, password: string) {
		// return this.http.post<any>(`${endpoint}/register`, { email, password });
	}

	fetchUserDetails() {
		return this.http.get<IResponse<{ user: User }>>(`${endpoint}/me`).pipe(map(res => res?.data?.user));
	}

	signinUser(signinDetails: LoginDTOSend) {
		return this.http.post<IResponse<{ user: User, jwt: string }>>(`${endpoint}/login`, signinDetails).pipe(
			map(response => response.data),
			tap(({jwt, user}) => {
				if (jwt) {
					localStorage.setItem(LOCAL_STORAGE_TOKEN, jwt);
					this.tokenSubject.next(jwt);
					this.userDetails.next(user);

					if (user.type === EUserType.Admin) {
						this.nutritionistsService.getAllNutritionists().subscribe();
					}
				}
			}));
	}

	logout() {
		localStorage.removeItem(LOCAL_STORAGE_TOKEN);
		localStorage.removeItem(LOCAL_STORAGE_PHONE);
		this.tokenSubject.next(null);
		this.userDetails.next(null);
	}

	isAuthenticated() {
		const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);

		if (token) {
			this.tokenSubject.next(token);
			return true;
		}
	}

	initializeOTP(initializeOTPDTO: { phoneNumber: any }) {
		return this.http.post<IResponse<{ user: User, jwt: string }>>(`${endpoint}/register`, initializeOTPDTO).pipe(
			map(response => response.data),
			tap(() => {
				localStorage.setItem(LOCAL_STORAGE_PHONE, initializeOTPDTO.phoneNumber);
			}));
	}

	verifyOTP(verifyOTPDTO: { phoneNumber?: string, verificationCode: string }) {
		verifyOTPDTO.phoneNumber = verifyOTPDTO.phoneNumber || localStorage.getItem(LOCAL_STORAGE_PHONE);

		return this.http.post<IResponse<{ user: User, jwt: string }>>(`${endpoint}/verify`, verifyOTPDTO).pipe(
			map(response => response.data),
			tap(({jwt, user}) => {
				if (jwt) {
					localStorage.setItem(LOCAL_STORAGE_TOKEN, jwt);
					this.tokenSubject.next(jwt);
					this.userDetails.next(user);
				}
			}));
	}
}
