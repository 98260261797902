import { Injectable } from '@angular/core';
import { Nutritionist } from '../models/nutritionist.interface';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../models/response.interface';
import { environment } from '../../../environments/environment';
import { defaultIfEmpty, filter, map, switchMap, tap } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { Menu } from '../models/menu.interface';
import { Ad } from '../models/ad.interface';
import { FilesService } from './files.service';
import { CreateNutritionistDto } from '../models/dto/create-nutritionist.dto';

const ADMIN_URL = environment.adminEndpoint;
const REGULAR_URL = environment.serverUrl;
const endpoint = ADMIN_URL + '/nutritionists';
const regularEndpoint = REGULAR_URL + '/nutritionist';

@Injectable({
	providedIn: 'root'
})
export class NutritionistsService {
	nutritionists$ = new BehaviorSubject<Nutritionist[]>([]);

	constructor(private http: HttpClient, private filesService: FilesService,
	            private formBuilder: FormBuilder) {
	}

	getAllNutritionists(): Observable<Nutritionist[]> {
		return this.http.get<IResponse<Nutritionist[]>>(endpoint).pipe(map((res) => {
			if (res.success) {
				this.nutritionists$.next(res.data);

				return res.data;
			}

			return [];
		}));
	}

	getInactiveNutritionists(): Observable<Nutritionist[]> {
		return this.http.get<IResponse<Nutritionist[]>>(`${endpoint}/query/inactive`).pipe(map((res) => {
			if (res.success) {
				this.nutritionists$.next(res.data);

				return res.data;
			}

			return [];
		}));
	}

	getNutritionistById(nutritionistId: number): Observable<Nutritionist> {
		return this.http.get<IResponse<Nutritionist>>(`${endpoint}/${nutritionistId}`).pipe(map((res) => {
			if (res.success) {
				return res.data;
			}
			console.log(`Failed to fetch nutritionist by id`);
			return null;
		}));
	}

	createNutritionist(nutritionist: CreateNutritionistDto): Observable<Nutritionist> {
		return this.http.post<IResponse<Nutritionist>>(`${endpoint}`, nutritionist).pipe(map((res) => {
			if (res.success) {
				return res.data;
			}

			console.log(`Failed to fetch nutritionist by id`);
			return null;
		}));
	}

	updateNutritionistDetails(nutritionistId: number, nutritionistDetails: any): Observable<Nutritionist> {
		// let profileImage$: Observable<string>;
		// let licenseImage$: Observable<string>;
		// if (nutritionistDetails.profileImage) {
		// 	profileImage$ = this.filesService.uploadFile(nutritionistDetails.profileImage);
		// }

		// if (nutritionistDetails.licenseImage) {
		// 	licenseImage$ = this.filesService.uploadFile(nutritionistDetails.licenseImage);
		// }

		// return forkJoin([profileImage$, licenseImage$].filter((x) => !!x)).pipe(
		// 	defaultIfEmpty([]),
		// 	switchMap(([profileImageUrl, licenseImageUrl]) => {
		// 		if (!profileImage$) {
		// 			licenseImageUrl = profileImageUrl;
		// 		}
		//
		// 		nutritionistDetails.licenseImageUrl = licenseImageUrl || undefined;
		// 		nutritionistDetails.profileImageUrl = profileImageUrl || undefined;

		return this.http.put<IResponse<Nutritionist>>(`${endpoint}/${nutritionistId}`, nutritionistDetails)
			.pipe(
				filter(response => response.success),
				map(response => response.data)
			);
		// }));
	}

	getNutritionistForm(nutritionist?: Nutritionist) {
		return this.formBuilder.group({
			phone: [nutritionist?.user?.phone, [Validators.required, Validators.pattern(/^\+?(972|0)?(\-)?([5]{1}\d{8})$/)]],
			email: [nutritionist?.user?.email, [Validators.required, Validators.email]],
			fullName: [nutritionist?.fullName, [Validators.required]],
			password: [, []]
		});
	}

	setMenu(nutritionistId: number, menu: Menu): Observable<Ad> {
		let menu$: Observable<string>;

		if (menu.pdfUrl) {
			menu$ = this.filesService.uploadFile(menu.pdfUrl as any);
		}

		return forkJoin([menu$].filter((x) => !!x)).pipe(
			defaultIfEmpty([]),
			switchMap(([pdfUrl]) => {
				if (pdfUrl) {
					menu.pdfUrl = pdfUrl;
				}

				return this.http.put<IResponse<Ad>>(`${endpoint}/${nutritionistId}/menu`, menu)
					.pipe(
						filter(response => response.success),
						map(response => response.data)
					);
			}));
	}

	deleteNutritionist(nutritionistId: number): Observable<string> {
		return this.http.delete<IResponse<string>>(`${endpoint}/${nutritionistId}`).pipe(
			filter(response => response.success),
			tap(() => this.getAllNutritionists().subscribe()),
			map(response => response.data)
		);
	}
}
